






















































































import TallaAdd from "@/components/admin/evento/TallaAdd.vue";
import Vue from "vue";
import ModificarDireccionPedido from "@/components/web/cliente/ModificarDireccionPedido.vue";
//import TallaService, { TallaCreateDto } from "@/api/TallaService";
import PedidoService, { PedidoCreatedDto } from "@/api/pedido/PedidoService";
export default Vue.extend({
  components: {
    ModificarDireccionPedido
  },
  metaInfo: {
    title: "Tallas"
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        descripcion: ""
      } as any,
      headers: [
        { text: "#", value: "id" },
        { text: "Fecha", value: "createdOn", sortable: false },
        { text: "Tipo Pago", value: "displayTipoPago" },
        { text: "Estado Pago", value: "displayEstadoPago" },
        { text: "Motivo Rechazo", value: "motivoRechazo" },
        //{ text: "Estado Envio", value: "displayEstadoEnvio" },
        //{ text: "Estado Entrega", value: "displayEstadoEntrega" },
        { text: "Total", value: "displayTotal" },
        //{ text: "Estado", value: "estado" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as PedidoCreatedDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        descripcion: ""
      };
      this.dato = newDto;
    },
    toSubirRecibo(id: string) {
      this.$router.push({
        path: "/proceso-pago/subir-papeleta",
        query: { idPedido: id }
      });
    },
    editItem(item: any) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      PedidoService.getMisPedidos()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Sus Pedidos",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
